/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
"use strict";
"use client";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import { googleSignIn } from "./AuthContext";
import { useEffect, useState } from "react";

export default function NotLoggedIn() {
  const [loginError, setLoginError] = useState<string | null>(null);

  const handleSignIn = async () => {
    try {
      await googleSignIn();
      setLoginError(null);
    } catch (error) {
      setLoginError(`Login failed with error: ${error}`);
    }
  };
  useEffect(() => {
    // Install a proxying service worker
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("/auth-service-worker.js", {
        scope: "/",
      });
    }
  }, []);

  return (
    <>
      {loginError && (
        <Alert severity="error">
          <AlertTitle>An Error Occurred</AlertTitle>
          {loginError}
        </Alert>
      )}
      <Alert severity="warning">Please login to view this page.</Alert>
      <Button color="inherit" onClick={handleSignIn}>
        Login with Google
      </Button>
    </>
  );
}
