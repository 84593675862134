/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * This wrapper shows a login screen for any child components and thus
 * requiring someone to login. It also handles the sign-in flow and error
 * messages.
 */

"use client";
"use strict";

import { ReactNode, useEffect, useState } from "react";
import { UserAuth } from "./AuthContext";
import NotLoggedIn from "./NotLoggedIn";
import CircularProgress from "@mui/material/CircularProgress";

interface RequireAuthWrapperProps {
  children?: ReactNode;
}

export default function RequireAuthWrapper({
  children,
}: Readonly<RequireAuthWrapperProps>): ReactNode {
  const [loading, setLoading] = useState<boolean>(true);

  // This page requires authentication, check we have that
  const { user } = UserAuth();
  useEffect(() => {
    const checkAuthentication = async () => {
      await new Promise((resolve) => setTimeout(resolve, 200));
      setLoading(false);
    };
    checkAuthentication();
  }, [user]);

  if (loading) {
    return <CircularProgress />;
  }

  if (!loading && user) {
    return <>{children}</>;
  }
  return <NotLoggedIn />;
}
